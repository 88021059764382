/* eslint-disable no-nested-ternary */

import axios from 'axios';

interface FormPayload {
  name: string;
  gender: string;
//   gender: 'male' | 'female';
  jobTitle: string;
  mobile: string;
  email: string;
  invoiceTitle: string;
  invoiceId: string;
  needReport: string;
//   needReport: 'yes' | 'no';
  reportTitle: string;
  needRoom: string;
//   needRoom: 'yes' | 'no';
  roomType: string;
//   roomType: 'single' | 'standard';
  checkInAt: string;
  checkOutAt: string;
}

// const

const transfromFormData = (data: FormPayload) => {
  const {
    name, email, gender, jobTitle, mobile, invoiceId, invoiceTitle, needReport, reportTitle,
    needRoom, roomType, checkInAt, checkOutAt,
  } = data;

  return {
    name,
    email,
    gender: gender === 'male' ? '男' : '女',
    jobTitle,
    mobile,
    invoiceId,
    invoiceTitle,
    needReport: needReport === 'yes' ? '是' : '否',
    reportTitle: needReport ? reportTitle : '',
    needRoom: needRoom === 'yes' ? '是' : '否',
    roomType: needRoom ? (roomType === 'single' ? '单人间' : '标准间') : '',
    checkInAt: needRoom ? checkInAt : '',
    checkOutAt: needRoom ? checkOutAt : '',
  };
};

export const name = 'service';

export const submitFormToServer: (data:FormPayload)=>Promise<boolean> = (data) => {
  const payload = transfromFormData(data);
  return axios.post('https://cl.cuger.cn/api/add', payload).then((resp) => {
    const body = resp.data;
    if (body.code !== 0) {
      return Promise.reject(Error(body.message || '网络错误'));
    }
    return true;
  }).catch(() => Promise.reject(Error('网络错误')));
};
