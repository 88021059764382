import { App } from 'vue';
import '@vant/touch-emulator';
import {
  Form,
  Button,
  Cell,
  CellGroup,
  Field,
  Radio,
  RadioGroup,
  DatetimePicker,
  Popup,
} from 'vant';

// import 'vant/lib/form/style';
// import 'vant/lib/button/style';
// import 'vant/lib/cell/style';
// import 'vant/lib/cell-group/style';
// import 'vant/lib/field/style';

import 'vant/lib/index.css';

export default function useVantComponents(app: App<Element>): void {
  [
    Form,
    Button,
    Cell,
    CellGroup,
    Field,
    Radio,
    RadioGroup,
    Popup,
    DatetimePicker,
  ].forEach((component) => {
    app.use(component);
  });
}
