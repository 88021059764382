import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import useVantComponents from './extends/vant';

const app = createApp(App);

useVantComponents(app);

app.use(router).mount('#app');
