
import { defineComponent } from 'vue';

import { Notify, Toast } from 'vant';

import { formatDateTime } from '../utils';

import { submitFormToServer } from '../service';

const PICKER_TITLE_MAP: Record<string, string> = {
  checkIn: '入住时间',
  checkOut: '退房时间',
};

const PICKER_FORMATTER_MAP: Record<string, string> = {
  year: '年',
  month: '月',
  day: '日',
  hour: '时',
  minute: '分',
};

export default defineComponent({
  name: 'Form',
  data: () => ({
    showDateTimePicker: false,
    currentPickerField: 'checkIn',
    minDate: new Date(2021, 5, 1),
    maxDate: new Date(2021, 5, 30),
    checkInAtDisplay: '请选择入住时间',
    checkOutAtDisplay: '请选择退房时间',
    form: {
      name: '',
      gender: 'male',
      jobTitle: '',
      mobile: '',
      email: '',
      invoiceTitle: '',
      invoiceId: '',
      needReport: 'yes',
      reportTitle: '',
      needRoom: 'yes',
      roomType: 'single',
      checkInAt: '',
      checkOutAt: '',
    },
  }),
  computed: {
    pickerTitle(): string {
      return PICKER_TITLE_MAP[this.currentPickerField];
    },
  },
  methods: {
    onSubmit() {
      Toast.loading({
        message: '正在提交...',
        forbidClick: true,
        loadingType: 'spinner',
        // duration: 0,
      });
      submitFormToServer(this.form)
        .then(() => {
          Toast.clear();
          this.$router.replace('/result');
        })
        .catch((err) => {
          Notify({ type: 'danger', message: err.message });
          Toast.fail('提交失败，请重试');
          console.log(err.message);
        });
    },
    onDateTimePickerConfirm(dateTime: Date) {
      const valueKey = `${this.currentPickerField}At` as
        | 'checkInAt'
        | 'checkOutAt';
      this.form[valueKey] = formatDateTime(dateTime);
      this.showDateTimePicker = false;
    },
    showPicker(field: string) {
      this.currentPickerField = field;
      this.showDateTimePicker = true;
    },

    pickerFormatter(type: string, val: string): string {
      return val + PICKER_FORMATTER_MAP[type];
    },
  },
});
