<template>
  <router-view />
</template>
<style>
html,
body,
#app {
  min-height: 100%;
  margin: 0 auto;
  max-width: 800px;
}

body {
  padding-bottom: 50px;
  padding-bottom: calc(50px + constant(safe-area-inset-bottom));
  padding-bottom: calc(50px + env(safe-area-inset-bottom));
  padding-bottom: calc(50px + env(safe-area-inset-bottom, 0px));
  background-color: #f5f5f7;
}

@media (min-width: 800px) {
  body {
    background: #fff;
  }
  #app {
    margin-top: 16px;
    border: 1px solid #ebedf0;
    border-radius: 20px;
    box-shadow: 0 8px 12px #ebedf0;
    background-color: #f5f5f7;
    padding: 0 24px 32px;
  }
  .van-popup {
    max-width: 480px;
    margin: auto;
    right: 0;
  }
  .van-cell-group {
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 8px 12px #ebedf0;
  }
}
</style>
