<template>
  <div class="form">
    <header>
      <h1>参会回执</h1>
      <h2>第四届全国青年工程风险分析和控制研讨会</h2>
    </header>
    <main>
      <van-form @submit="onSubmit" validate-trigger="onSubmit" scroll-to-error>
        <!-- 1 -->
        <van-cell-group title="1.基本信息">
          <van-field
            v-model="form.name"
            name="name"
            label="姓名"
            placeholder="请输入姓名"
            :rules="[{ required: true, message: '请填写姓名' }]"
          />
          <van-field name="gender" label="性别">
            <template #input>
              <van-radio-group v-model="form.gender" direction="horizontal">
                <van-radio name="male">男</van-radio>
                <van-radio name="female">女</van-radio>
              </van-radio-group>
            </template>
          </van-field>
          <van-field
            v-model="form.jobTitle"
            name="title"
            label="职称"
            placeholder="请输入职称"
            :rules="[{ required: true, message: '请输入职称' }]"
          />
        </van-cell-group>
        <!-- 2 -->
        <van-cell-group title="2.联系方式">
          <van-field
            v-model="form.email"
            name="email"
            type="email"
            label="Email"
            placeholder="请输入 Email"
            :rules="[{ required: true, message: '请输入 Email' }]"
          />
          <van-field
            v-model="form.mobile"
            name="mobile"
            label="联系电话"
            type="tel"
            placeholder="请输入联系电话"
            :rules="[
              { pattern: /^1[3456789]\d{9}$/, message: '请输入正确的手机号' },
            ]"
          />
        </van-cell-group>
        <!-- 3 -->
        <section class="invoice-field">
          <van-cell-group title="3.发票信息">
            <van-field
              v-model="form.invoiceTitle"
              name="invoiceTitle"
              label="单位全称"
              placeholder="请输入发票单位全称"
            />
            <van-field
              v-model="form.invoiceId"
              name="invoiceId"
              label="纳税人识别号"
              placeholder="请输入发票纳税人识别号"
            />
          </van-cell-group>
        </section>
        <!-- 4 -->
        <van-cell-group title="4.学术报告信息">
          <van-field
            class="report-field"
            name="needReport"
            label="是否做学术报告"
          >
            <template #input>
              <van-radio-group v-model="form.needReport" direction="horizontal">
                <van-radio name="yes">是</van-radio>
                <van-radio name="no">否</van-radio>
              </van-radio-group>
            </template>
          </van-field>
          <van-field
            v-if="form.needReport === 'yes'"
            v-model="form.reportTitle"
            name="reportTitle"
            label="报告题目"
            placeholder="请输入报告题目"
            :rules="[{ required: form.needReport, message: '请输入报告题目' }]"
          />
        </van-cell-group>

        <!-- 5 -->
        <van-cell-group title="5.酒店信息（武汉光谷金盾大酒店）">
          <van-field name="needRoom" label="预订方式">
            <template #input>
              <van-radio-group v-model="form.needRoom" direction="horizontal">
                <van-radio name="yes">统一预订</van-radio>
                <van-radio name="no">自行预订</van-radio>
              </van-radio-group>
            </template>
          </van-field>
          <template v-if="form.needRoom === 'yes'">
            <van-field name="roomType" label="房间类型" center>
              <template #input>
                <van-radio-group class="room-type-field"  v-model="form.roomType">
                  <van-radio name="single">单人间（450元/晚）</van-radio>
                  <van-radio name="standard">标准间（480元/晚）</van-radio>
                </van-radio-group>
              </template>
            </van-field>
            <van-field
              v-model="form.checkInAt"
              readonly
              clickable
              name="checkInAt"
              label="入住时间"
              placeholder="请选择入住时间"
              :rules="[
                {
                  required: form.needRoom === 'yes',
                  message: '请选择入住时间',
                },
              ]"
              @click="showPicker('checkIn')"
            >
            </van-field>
            <van-field
              v-model="form.checkOutAt"
              readonly
              clickable
              name="checkOutAt"
              label="退房时间"
              :rules="[
                {
                  required: form.needRoom === 'yes',
                  message: '请选择退房时间',
                },
              ]"
              placeholder="请选择退房时间"
              @click="showPicker('checkOut')"
            >
            </van-field>
          </template>
        </van-cell-group>
        <div class="submit-button">
          <van-button round block type="primary" native-type="submit">
            提交
          </van-button>
        </div>
      </van-form>
      <van-popup round v-model:show="showDateTimePicker" position="bottom">
        <van-datetime-picker
          :formatter="pickerFormatter"
          :title="pickerTitle"
          :min-date="minDate"
          :max-date="maxDate"
          type="month-day"
          @confirm="onDateTimePickerConfirm"
          @cancel="showDateTimePicker = false"
        />
      </van-popup>
    </main>
    <footer></footer>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import { Notify, Toast } from 'vant';

import { formatDateTime } from '../utils';

import { submitFormToServer } from '../service';

const PICKER_TITLE_MAP: Record<string, string> = {
  checkIn: '入住时间',
  checkOut: '退房时间',
};

const PICKER_FORMATTER_MAP: Record<string, string> = {
  year: '年',
  month: '月',
  day: '日',
  hour: '时',
  minute: '分',
};

export default defineComponent({
  name: 'Form',
  data: () => ({
    showDateTimePicker: false,
    currentPickerField: 'checkIn',
    minDate: new Date(2021, 5, 1),
    maxDate: new Date(2021, 5, 30),
    checkInAtDisplay: '请选择入住时间',
    checkOutAtDisplay: '请选择退房时间',
    form: {
      name: '',
      gender: 'male',
      jobTitle: '',
      mobile: '',
      email: '',
      invoiceTitle: '',
      invoiceId: '',
      needReport: 'yes',
      reportTitle: '',
      needRoom: 'yes',
      roomType: 'single',
      checkInAt: '',
      checkOutAt: '',
    },
  }),
  computed: {
    pickerTitle(): string {
      return PICKER_TITLE_MAP[this.currentPickerField];
    },
  },
  methods: {
    onSubmit() {
      Toast.loading({
        message: '正在提交...',
        forbidClick: true,
        loadingType: 'spinner',
        // duration: 0,
      });
      submitFormToServer(this.form)
        .then(() => {
          Toast.clear();
          this.$router.replace('/result');
        })
        .catch((err) => {
          Notify({ type: 'danger', message: err.message });
          Toast.fail('提交失败，请重试');
          console.log(err.message);
        });
    },
    onDateTimePickerConfirm(dateTime: Date) {
      const valueKey = `${this.currentPickerField}At` as
        | 'checkInAt'
        | 'checkOutAt';
      this.form[valueKey] = formatDateTime(dateTime);
      this.showDateTimePicker = false;
    },
    showPicker(field: string) {
      this.currentPickerField = field;
      this.showDateTimePicker = true;
    },

    pickerFormatter(type: string, val: string): string {
      return val + PICKER_FORMATTER_MAP[type];
    },
  },
});
</script>
<style scoped>
header {
  padding: 16px 24px 0;
}
header h1 {
  font-size: 32px;
  font-weight: 400;
  color: #202124;
  line-height: 135%;
  margin: 16px 0;
}

header h2 {
  white-space: pre-wrap;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.2px;
  line-height: 20px;
  color: #646566;
  margin-top: 12px;
}

.submit-button {
  padding: 16px;
  margin-top: 18px;
}

@media (min-width: 800px) {
  .form {
    width: 60%;
    margin: 0 auto;
  }
}
</style>
<style>

.form .van-cell-group__title {
  font-size: 16px;
  color: #646566;
  font-weight: bold;
  margin-top: 12px;
}

.form .van-cell-group__title::after {
  content: " *";
  color: #ff0000;
}

.room-type-field .van-radio + .van-radio {
  margin-top: 12px;

}

.invoice-field .van-cell-group__title::after {
  display: none;
}
.report-field .van-field__label {
  width: 7.5em;
  margin-right: 16px;
}
</style>
